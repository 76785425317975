var homeLayout = function homeLayout() {
  return import('src/layouts/HomeLayout.vue');
};
var bitpassLayout = function bitpassLayout() {
  return import('src/layouts/BitpassLayout.vue');
};
var salesLayout = function salesLayout() {
  return import('src/layouts/SalesLayout.vue');
};
// <router-link :to="{ name: 'user', params: { userId: 123 }}">User</router-link>

/// MAIN .------------------------------------------------------------------------------------
var routes = [
// router.push({ name: 'user', params: { userId: 123 }})

/* order has to be:
  /agent/backoffice
  /agent/:id
  /agent */
{
  path: '/agent/backoffice',
  component: salesLayout,
  children: [{
    path: '',
    name: 'agent-backoffice',
    component: function component() {
      return import('src/pages/agent/backoffice.vue');
    }
  }]
}, {
  path: '/',
  component: homeLayout,
  children: [{
    path: '',
    name: 'home',
    component: function component() {
      return import('src/pages/home.vue');
    }
  }, {
    path: 'learn',
    name: 'learn',
    component: function component() {
      return import('src/pages/learn.vue');
    }
  }, {
    path: 'pricing',
    name: 'pricing',
    component: function component() {
      return import('src/pages/pricing.vue');
    }
  }, {
    path: 'company',
    name: 'company',
    component: function component() {
      return import('src/pages/company.vue');
    }
  }, {
    path: 'agent/:id',
    name: 'agent-id',
    component: function component() {
      return import('src/pages/agent.vue');
    }
  }, {
    path: 'agent',
    name: 'agent',
    component: function component() {
      return import('src/pages/agent.vue');
    }
  }, {
    path: 'privacy',
    name: 'privacy',
    component: function component() {
      return import('src/pages/privacy.vue');
    }
  }, {
    path: 'cookies',
    name: 'cookies',
    component: function component() {
      return import('src/pages/cookies.vue');
    }
  }, {
    path: 'legal',
    name: 'legal',
    component: function component() {
      return import('src/pages/legal.vue');
    }
  }]
}, {
  path: '/board',
  component: bitpassLayout,
  children: [{
    path: '',
    name: 'board',
    component: function component() {
      return import('pages/board.vue');
    }
  }, {
    path: 'client/:id',
    name: 'board-client',
    component: function component() {
      return import('pages/board.vue');
    }
  }]
}, {
  path: '/resetpassword',
  component: bitpassLayout,
  children: [{
    path: ':id',
    name: 'resetpassword-id',
    component: function component() {
      return import('pages/resetpassword.vue');
    }
  }]
}, {
  path: '/notfound',
  component: bitpassLayout,
  children: [{
    path: '',
    name: 'notfound',
    component: function component() {
      return import('pages/NotFound.vue');
    }
  }]
}, {
  path: '/help',
  component: bitpassLayout,
  children: [{
    path: 'demo',
    name: 'help-demo',
    component: function component() {
      return import('pages/help/demo.vue');
    }
  }, {
    path: 'settings',
    name: 'help-settings',
    component: function component() {
      return import('pages/help/settings.vue');
    }
  }, {
    path: 'inheritances',
    name: 'help-inheritances',
    component: function component() {
      return import('pages/help/inheritance.vue');
    }
  }]
}];
routes.push({
  path: '*',
  component: function component() {
    return import('pages/NotFound.vue');
  }
});
export default routes;